<template>
  <Base />
</template>

<script>
import Base from './layouts/Base.vue'

export default {
  components: { Base },
  name: 'App',

  data: () => ({
    //
  })
}
</script>
