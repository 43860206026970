<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <v-container class="mx-5" fluid>
    <v-card
      class="mx-auto"
      elevation="5"
      max-width="1000px"
    >
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Home',

  data() {
    return {
    }
  },

  computed: {
  },

  methods: {
  },

  components: {
  },

  created() {
  }
}
</script>
