export default [
  {
    path: ':program/galleries',
    name: 'galleries.index',
    component: () => import('@/views/galleries/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/galleries/create',
    name: 'galleries.create',
    component: () => import('@/views/galleries/Create.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/galleries/:id/edit',
    name: 'galleries.edit',
    component: () => import('@/views/galleries/Edit.vue'),
    meta: { auth: true }
  },
]
