import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { useAuthStore } from '@/store/auth'
import nextLevel from './nextLevel'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: HomeView,
    meta: { auth: true },
    children: [
      {
        path: '/folders',
        name: 'folders.index',
        component: () => import('@/views/Folders.vue'),
        meta: { auth: true }
      },
      {
        path: '/folders/:id',
        name: 'folders.show',
        component: () => import('@/views/Item.vue'),
        meta: { auth: true }
      },
    ]
  },
  {
    path: '/login',
    name: 'auth.login',
    component: () => import('@/views/auth/Login.vue'),
    meta: { auth: false }
  },
  ...nextLevel,
]

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes
})


router.beforeEach((to, from, next) => {
  const AuthStore = useAuthStore()
  const isAuth = AuthStore.isLoggedIn
  const publicPages = ['auth.login']
  let isPublicPage = publicPages.includes(to.name)
  let isNotLoginPage = to.name != publicPages[0]

  if (isPublicPage != false) { // if one of public pages go to them
    return next()
  } else { // not public page
    if (isAuth != false) { // auth user
      if (isNotLoginPage != true) { // if login page redirct to dashboard
        return next({ name: 'dashboard' })
      } else {
        return next()
      }
    } else { // not auth user
      return next({ name: publicPages[0] })
    }
  }
});

export default router
