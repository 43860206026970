export default [
  {
    path: ':program/workshops',
    name: 'workshops.index',
    component: () => import('@/views/workshops/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/workshops/create',
    name: 'workshops.create',
    component: () => import('@/views/workshops/Create.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/workshops/:id/edit',
    name: 'workshops.edit',
    component: () => import('@/views/workshops/Edit.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/workshops/:id/show',
    name: 'workshops.show',
    component: () => import('@/views/workshops/Show.vue'),
    meta: { auth: true }
  },
]
