import {httpInstance} from '@/plugins/http'
// import httpInstance from 'httpInstance'
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      auth_status: 'GUEST',
      userData: JSON.parse(localStorage.getItem('auth')) || {},
      // userToken: true,
      userToken: localStorage.getItem('token'),
      loading: false
    }
  },
  getters: {
    // isLoggedIn: (state) => state.userToken,
    isLoggedIn: (state) => state.userToken != null,
    isLoading: (state) => state.loading,
    authUser: (state) => state.userData,
  },
  actions: {
    login(authData){
      return new Promise((resolve, reject) => {
        httpInstance.post('/auth/admin/login', authData)
        .then((resp) => {
          this.userToken = resp.data.token
          this.userData = resp.data.user
          this.loading = false

          localStorage.setItem('token', this.userToken)

          localStorage.setItem('auth', JSON.stringify(this.userData))

         httpInstance.defaults.headers.common['Authorization'] = `Bearer ${resp.data.token}`

          console.log(httpInstance.defaults.headers)

          resolve(resp)
        })
        .catch(err => {
          this.auth_status = err

          localStorage.removeItem('token')

          reject(err)
        })
      })
    },
    logout(){
      return new Promise((resolve, reject) => {
        try {
          localStorage.clear();

          this.userToken = null

          delete httpInstance.defaults.headers.common['Authorization']

          this.auth_status = "Logout"

          resolve(this.auth_status)
        } catch (error) {
          reject(error)
        }
      })
    },
  }
})
