export default [
  {
    path: ':program/videos',
    name: 'videos.index',
    component: () => import('@/views/videos/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/videos/create',
    name: 'videos.create',
    component: () => import('@/views/videos/Create.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/videos/:id/edit',
    name: 'videos.edit',
    component: () => import('@/views/videos/Edit.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/videos/:id/show',
    name: 'videos.show',
    component: () => import('@/views/videos/Show.vue'),
    meta: { auth: true }
  },
]
