export default [
  {
    path: ':program/winners',
    name: 'winners.index',
    component: () => import('@/views/winners/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/winners/en/create',
    name: 'winners.create.en',
    component: () => import('@/views/winners/CreateEn.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/winners/en/:id/edit',
    name: 'winners.edit.en',
    component: () => import('@/views/winners/EditEn.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/winners/ar/:id/edit',
    name: 'winners.edit.ar',
    component: () => import('@/views/winners/EditAr.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/winners/:id/show',
    name: 'winners.show',
    component: () => import('@/views/winners/Show.vue'),
    meta: { auth: true }
  }
]
