export default [
  {
    path: ':program/scrolly-Tellings/:id/slides',
    name: 'slides.index',
    component: () => import('@/views/slides/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/scrolly-Tellings/:id/slides/create',
    name: 'slides.create',
    component: () => import('@/views/slides/Create.vue'),
    meta: { auth: true },
  },
  {
    path: ':program/scrolly-Tellings/:id/slides/:slide/edit',
    name: 'slides.edit',
    component: () => import('@/views/slides/Edit.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/scrolly-Tellings/:id/slides/:slide/show',
    name: 'slides.show',
    component: () => import('@/views/slides/Show.vue'),
    meta: { auth: true }
  },
]
