export default [
  {
    path: ':program/subs/:sid/scrollyTellings',
    name: 'subs.scrollyTellings.index',
    component: () => import('@/views/subs/scrollyTellings/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/scrollyTellings/create',
    name: 'subs.scrollyTellings.create',
    component: () => import('@/views/subs/scrollyTellings/Create.vue'),
    meta: { auth: true }
  },
  {
    path: ':program//subs/:sid/scrollyTellings/:id/edit',
    name: 'subs.scrollyTellings.edit',
    component: () => import('@/views/subs/scrollyTellings/Edit.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/scrollyTellings/:id/show',
    name: 'subs.scrollyTellings.show',
    component: () => import('@/views/subs/scrollyTellings/Show.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/videos',
    name: 'subs.videos.index',
    component: () => import('@/views/subs/videos/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/videos/create',
    name: 'subs.videos.create',
    component: () => import('@/views/subs/videos/Create.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/videos/:id/edit',
    name: 'subs.videos.edit',
    component: () => import('@/views/subs/videos/Edit.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/videos/:id/show',
    name: 'subs.videos.show',
    component: () => import('@/views/subs/videos/Show.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/galleries',
    name: 'subs.galleries.index',
    component: () => import('@/views/subs/galleries/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/galleries/create',
    name: 'subs.galleries.create',
    component: () => import('@/views/subs/galleries/Create.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/galleries/:id/edit',
    name: 'subs.galleries.edit',
    component: () => import('@/views/subs/galleries/Edit.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/subs/:sid/galleries/:id/show',
    name: 'subs.galleries.show',
    component: () => import('@/views/subs/galleries/Show.vue'),
    meta: { auth: true }
  },
]
