export default [
  {
    path: ':program/counters',
    name: 'counters.index',
    component: () => import('@/views/counters/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/counters/:id/edit',
    name: 'counters.edit',
    component: () => import('@/views/counters/Edit.vue'),
    meta: { auth: true }
  },
]
