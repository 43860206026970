<template>
  <v-app id="inspire">
    <side-bar v-if="isLoggedIn"></side-bar>

    <v-app-bar v-if="isLoggedIn" app elevation="1">
      <v-toolbar-title class="text--disabled">Edit Page</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SideBar from '@/layouts/components/SideBar.vue'
import { useAuthStore } from '@/store/auth'
import { mapState } from 'pinia'
export default {
  components: { SideBar },
  data: () => ({ drawer: null, name: '' }),
  computed: {
    ...mapState(useAuthStore, ['isLoggedIn'])
  },
  created(){
    this.name = this.$route.params.program
  }
}
</script>
