export default [
  {
    path: ':program/scrollyTellings',
    name: 'scrollyTellings.index',
    component: () => import('@/views/scrollyTellings/Index.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/scrollyTellings/create',
    name: 'scrollyTellings.create',
    component: () => import('@/views/scrollyTellings/Create.vue'),
    meta: { auth: true },
  },
  {
    path: ':program/scrollyTellings/:id/edit',
    name: 'scrollyTellings.edit',
    component: () => import('@/views/scrollyTellings/Edit.vue'),
    meta: { auth: true }
  },
  {
    path: ':program/scrollyTellings/:id/show',
    name: 'scrollyTellings.show',
    component: () => import('@/views/scrollyTellings/Show.vue'),
    meta: { auth: true }
  }
]
