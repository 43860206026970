import winners from './routes/winners';
import Program from '@/layouts/Program.vue'
import counters from './routes/counters';
import workshops from './routes/workshops';
import galleries from './routes/galleries';
import videos from './routes/videos';
import slides from './routes/slides';
import scrollyTellings from './routes/scrollyTellings';
import subs from './routes/subs';

export default [
  {
    path: '/:space',
    component: Program,
    meta: { auth: true },
    children: [
      {
        path: ':program',
        name: 'programs.index',
        component: () => import('@/views/programs/Index.vue'),
        meta: { auth: true },
      },
      {
        path: ':program/subs/:sid',
        name: 'subs.index',
        component: () => import('@/views/programs/SubIndex.vue'),
        meta: { auth: true },
      },
      ...winners,
      ...workshops,
      ...counters,
      ...galleries,
      ...videos,
      ...scrollyTellings,
      ...slides,
      ...subs,
    ]
  }
]
