<template>
  <v-navigation-drawer app :color="sideBarColor" dark>
    <v-list dark >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><span>{{ 'Next Level Admin' }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item active-class="white--text">
        <v-list-item-icon>
          <v-icon>{{ 'mdi-view-dashboard' }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ 'Dashboard' }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group active-class="white--text" color="white" :value="newSpaces" prepend-icon="mdi-account-circle">
        <template v-slot:activator>
          <v-list-item-title @click="newSpaces = !newSpaces">New Spaces</v-list-item-title>
        </template>

        <v-list-item @click="changeState()" v-for="item in nsItems" :key="item.title" link :to="{ name: item.routeName, params: { space: item.space, program: item.title } }">
          <v-list-item-icon>
            <v-icon v-if="typeof item.icon == 'string'" >{{ item.icon }}</v-icon>
            <v-img  v-if="typeof item.icon === 'string'" width="2rem" contain :src="item.icon"/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group active-class="white--text" color="white" :value="true" prepend-icon="mdi-account-circle">
        <template v-slot:activator>
          <v-list-item-title @click="nextLevel = !nextLevel">Next Level</v-list-item-title>
        </template>

        <v-list-item @click="changeState()" v-for="item in nlItems" :key="item.title" link :to="{ name: item.routeName, params: { space: item.space, program: item.title } }">
          <v-list-item-icon>
            <v-icon v-if="typeof item.icon == 'string'" >{{ item.icon }}</v-icon>
            <v-img  v-if="typeof item.icon === 'string'" width="2rem" contain :src="item.icon"/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item active-class="white--text" @click="logoutUser">
        <v-list-item-icon>
          <v-icon>{{ 'mdi-logout' }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ 'Logout' }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {useAuthStore} from '@/store/auth';
import { useProgramStore } from '@/store/program';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'SideBar',
  setup(){
    const programStore = useProgramStore()
    return{ programStore }

  },
  data() {
    return {
      newSpaces: false,
      nextLevel: false,
      nlItems: [
        { title: 'Media-labs', icon: require('../../assets/media-labs-pattern.svg'), routeName: 'programs.index', space: 'next-level' },
        { title: 'Creators-hubs', icon: 'mdi-gavel', routeName: 'programs.index', space: 'next-level' },
        { title: 'Production-funds', icon: 'mdi-gavel', routeName: 'programs.index', space: 'next-level' },
        { title: 'Cultural-policies', icon: 'mdi-gavel', routeName: 'programs.index', space: 'next-level' },
        { title: 'Jeenak-sudan-collage', icon: 'mdi-account-box', routeName: 'programs.index', space: 'next-level' },
      ],
      nsItems: [
        { title: 'Jeenak', icon: 'mdi-account-box', routeName: 'programs.index', space: 'new-spaces' },
        { title: 'Media-labs', icon: 'mdi-gavel', routeName: 'programs.index', space: 'new-spaces' },
        { title: 'Creators-hubs', icon: 'mdi-gavel', routeName: 'programs.index', space: 'new-spaces' },
        { title: 'Production-funds', icon: 'mdi-gavel', routeName: 'programs.index', space: 'new-spaces' },
        { title: 'Art-meets-cultural-policies', icon: 'mdi-gavel', routeName: 'programs.index', space: 'new-spaces' },
      ],
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['logout']),
    async changeState(){
      await this.programStore.index(this.$route.params.space, this.$route.params.program)

      await this.programStore.subPrograms(this.$route.params.space, this.$route.params.program)
    },
    async logoutUser(){
      this.logout()
        .then(res => {
          console.log(res)
          this.$router.push({ name: 'auth.login' })
        })
        .catch(err => console.log(err))
    },
    async goToRoute(item){
      let params = { 
        name: item.routeName,
        params: { 
          space: item.space,
          program: item.title 
        } 
      }

      await this.$router.push(params)
    }
  },
  computed:{
    // v-if="item.title === authUser.program"
    ...mapState(useAuthStore, ['authUser']),
    sideBarColor:function(){
      if (this.$route.path == '/next-level/Creators-hub') {
        return '#FE85A0'
    
      } else if (this.$route.path == '/new-spaces/Media-labs') {
        return '#87D335'
    
      } else if (this.$route.path == '/next-level/Media-labs') {
    
        return '#20B2E2'
    
      } else if (this.$route.path == '/new-spaces/Cultural-policies') {
        return '#677B85'
    
      } else if (this.$route.path == '/next-level/Art-meets-cultural-policies') {
        return '#CAA0E0'
    
      } else if (this.$route.path == '/new-spaces/Jeenak') {
        return '#F55C00'
    
      } else if (this.$route.path == '/next-level/Jeenak') {
        return '#397A4E'
    
      } else if (this.$route.path == '/new-spaces/Production-funds') {
        return '#890065'
    
      } else if (this.$route.path == '/next-level/Production-funds') {
        return '#9DD74E'
    
      } else { return 'primary' }
    },    
  }
}
</script>
