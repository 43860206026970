import {httpInstance} from "@/plugins/http"
import { acceptHMRUpdate, defineStore } from "pinia"

export const useProgramStore = defineStore('program', {
  state: () => ({
    blocks: [],
    subs: [],
    error: null,
    success: false,
    loading: false,
  }),
  getters: {
    isLoading: state => state.loading,
    hasSubPrograms: state => state.success,
    getSubsData: state => state.subs,
    getBlocksData: state => state.blocks,
  },
  actions: {
    async index(space, program) {
      this.loading = true
      await httpInstance.get(`/${space}/${program}/blocks`)
      .then(async (res) => {
        if (res.status === 200) {
          this.blocks = res.data
          this.loading = false
        } else {
          await this.setError()
        }
      })
      .catch((err) => {
        this.error = err
      })
    },
    async subPrograms(space, program) {
      this.loading = true
      await httpInstance.get(`/${space}/${program}/subs`)
      .then(async (res) => {
        if (res.status === 200) {
          this.subs = res.data.subs
          this.success = res.data.success
          this.loading = false
        } else {
          await this.setError()
        }
      })
      .catch((err) => {
        this.error = err
      })
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProgramStore, import.meta.hot))
}
