import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: '#00B3DB',
        secondary: '#D9D9D9',
        accent: '#8c9eff',
        error: '#b71c1c',
        background: '#CFD8DC'
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
})
