import axios from 'axios';

export const httpInstance = axios.create({
  baseURL: "https://api.nextlevel.sd/api",
  // baseURL: 'http://localhost:3333/api',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'multipart/form-data',
    'X-Requested-With': 'XMLHttpRequest',
  }
})
